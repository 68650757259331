<template>
	<div>
		<div class="box">
			<div class="title_box flex">
				<div class="flex" style="flex-wrap: wrap;">
					<h3 v-show="Title.id == 0 || Title.id == 1" :class="Title.id == 0 ? 'TitleHover' : 'h3'"
						@click="TitleShow('提现记录列表', 0)">
						提现记录列表
					</h3>
					<h3 v-show="Title.id == 0 || Title.id == 1" :class="Title.id == 1 ? 'TitleHover' : 'h3'"
						@click="TitleShow('支付宝设置', 1)">
						支付宝设置
					</h3>
					<h3 v-show="Title.id == 2" :class="Title.id == 2 ? 'TitleHover' : 'h3'">
						申请提现
					</h3>
					<p style="width: 100%;margin-top: 10px;color: #ff0000; font-size: 16px;">提现10-15个工作日内处理完毕,周末节假日除外,最迟不超过15天</p>
				</div>
				<div class="flex">
					<button @click="TitleShow('申请提现', 2)" class="add_but" v-show="Title.id == 0">
						申请提现
					</button>
					<!-- <button class="add_but2" v-show="Title == '支付宝设置'">取消</button> -->
					<button class="add_but3" v-show="Title.id == 1" @click="alipaySet()">保存</button>
					<button class="add_but3" v-show="Title.id == 2" @click="TitleShow('提现记录列表', 0)">返回</button>
					<button class="add_but3" v-show="Title.id == 2" @click="Tocash()">提交</button>
				</div>
			</div>
			<div class="ct_box" v-show="Title.id == 0">
				<div class="search-status_box flex">
					<div class="search_box flex">
						<el-date-picker v-model="searchTime" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" @change="searchBut()">
						</el-date-picker>
					</div>
					<ul class="status_ul flex">
						<li :class="status == item.id ? 'active' : ''" v-for="(item, index) in statusUl" :key="index"
							@click="statusShow(item.id)">
							{{ item.title }}
						</li>
					</ul>
				</div>
				<table class="table">
					<thead>
						<tr>
							<td>申请时间</td>
							<td>提现金额</td>
							<td>提现方式</td>
							<td>户名</td>
							<td>姓名</td>
							<td>受理时间</td>
							<td>处理状态</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in listTable" :key="index">
							<td>{{ item.ctime }}</td>
							<td>{{ item.amount }}</td>
							<td>{{ typeList[item.type] }}</td>
							<td>{{ item.account }}</td>
							<td>{{ item.name }}</td>
							<td>{{ item.utime }}</td>
							<td
								:style="{ color: item.status == 0 ? '#333' : item.status == 1 ? '#00B052' : '#FF0000' }">
								{{
						statusList[item.status] }} <span v-if="item.status == 2">: {{ item.reason }}</span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
			<div class="ct_box" v-show="Title.id == 1">
				<div class="flex" style="align-items: center; margin-bottom: 3rem">
					<p class="text_title">支付宝账号:</p>
					<div class="text_input">
						<el-input v-model="AlipayId" placeholder="输入支付宝账号"></el-input>
					</div>
				</div>
				<div class="flex" style="align-items: center">
					<p class="text_title">姓名:</p>
					<div class="text_input">
						<el-input v-model="name" placeholder="输入姓名"></el-input>
					</div>
				</div>
			</div>
			<div class="ct_box" v-show="Title.id == 2">
				<el-radio-group v-model="form.type" style="margin-bottom: 30px;">
					<el-radio-button :label='1'>充值金额</el-radio-button>
					<el-radio-button :label='2'>收益金额</el-radio-button>
				</el-radio-group>
				<div>
					<div class="flex">
						<p class="text_remainingSum">{{ form.type == 1 ? '充值金额:' : '收益余额:' }}</p>
						<span class="money">￥{{ form.type == 1 ? user_info.money : user_info.wallet }}</span>
					</div>
					<div class="flex" style="align-items: center; margin-bottom: 15px;">
						<p class="text_title">提现金额:</p>
						<div class="text_input">
							<el-input @input="changeDot" v-model="form.amount" placeholder="输入金额"></el-input>
						</div>
						元
					</div>
				</div>
				<!-- <div v-show="form.type == 2">
					<div class="flex">
						<p class="text_remainingSum">收益余额:</p>
						<span class="money">￥</span>
					</div>
					<div class="flex" style="align-items: center; margin-bottom: 15px;">
						<p class="text_title">提现金额:</p>
						<div class="text_input">
							<el-input @input="changeDot" v-model="form.amount" placeholder="输入金额"></el-input>
						</div>
						元
					</div>
				</div> -->
				<p class="hitm" v-show="form.type == 1">提示：{{
						`提现充值金额，需扣除10%手续费,实际到账：${formatNumber ? formatNumber : '0.00'}` }}。</p>
				<div class="flex" style="align-items: center">
					<p class="text_title">提现方式:</p>
					<div style="margin-left: 15px"><el-radio v-model="form.radioType" :label='1'>支付宝</el-radio></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
export default {
	components: {
		Title,
	},
	created() {
		this.$store.commit('childTab', '提现管理')
		this.listFn();
		this.user_info = JSON.parse(localStorage.getItem('user_info'))
	},
	computed: {
		formatNumber: function () {
			// 匹配整数
			let formatNum = this.form.amount
			let transactionFee = 0
			let resultAfterFee = 0
			const integerPattern = /^\d+$/;
			// 匹配带一位小数的数字
			const oneDecimalPattern = /^\d+\.\d$/;
			if (this.form.type == 1) {
				transactionFee = formatNum * 9 / 10
			}
			if (integerPattern.test(transactionFee)) {
				// 如果是整数，补.00
				return transactionFee + '.00'
			} else if (oneDecimalPattern.test(transactionFee)) {
				// 如果是带一位小数的数字，补0.10
				return transactionFee + '0';
			} else {
				return transactionFee.toFixed(2)
			}
		}
	},
	data() {
		return {
			time: '',
			resource: '',
			orderId: '',
			statusUl: [{ id: '', title: '全部', }, { id: '0', title: '待审核', }, { id: '1', title: '通过', }, { id: '2', title: '拒绝', },],
			Title: {
				id: 0,
				name: '提现记录列表'
			},
			status: '',
			AlipayId: '', //支付宝账号
			name: '', //名称
			check: false, //提现方式
			form: {
				type: 1,
				amount: 0,
				radioType: 0,
			},
			typeList: { 1: '支付宝' },
			statusList: { 0: '待处理', 1: '通过', 2: '拒绝' },
			listTable: [],
			page: 1,
			total_page: 0,
			count: 0, //总条数
			searchTime: "",
			user_info: {},
		}
	},
	watch: {
		'form.type': {
			handler(newValue, oldVale) {
				if (newValue != oldVale) {
					this.form.amount = ''
					this.form.radioType = 0
				}
			},
			deep: true,
		},
	},
	methods: {
		changeDot(value) {
			console.log(value);
			const regex = /^\d+(\.\d{1,2})?$/;
			if (!regex.test(value)) {
				
			}
		},
		statusShow(id) {
			this.status = id
			this.listFn()
		},
		TitleShow(item, type) {
			this.Title = {
				id: type,
				name: item
			}
			if (type == 1) {
				this.curlGet("/api/users/alipay/get").then((res) => {
					if (res.data.code) {
						(this.AlipayId = res.data.data.account),
							(this.name = res.data.data.name)
					}
				})
			}
		},
		pageChange(pageVal) {
			this.page = pageVal
			this.listFn()
		},
		listFn() {
			let where = { page: this.page }
			if (this.status) {
				where.status = this.status
			}
			if (this.searchTime) {
				where.start_time = this.searchTime[0].getTime() / 1000;
				where.end_time = this.searchTime[1].getTime() / 1000;
			}
			this.curlGet("/api/users/tocash", where).then((res) => {
				if (res.data.code) {
					this.listTable = res.data.data.list
					this.total_page = res.data.data.total_page
					this.page = this.page
					this.count = res.data.data.count
				}
			})
		},
		// 筛选
		searchBut() {
			this.listFn();
		},
		// 支付宝设置
		alipaySet() {
			if (this.AlipayId == '') {
				this.$message({
					type: 'error',
					message: '支付宝账号不能为空'
				});
				return false;
			}
			if (this.name == '') {
				this.$message({
					type: 'error',
					message: '姓名不能为空'
				});
				return false;
			}
			this.curlPost("/api/users/alipay/set", {
				'account': this.AlipayId,
				'name': this.name,
			}).then((res) => {
				if (res.data.code) {
					this.$message({
						type: 'success',
						message: '设置成功'
					});
				}
			})
		},
		// 提现
		Tocash() {
			// if (this.amount < 100) {
			// 	this.$message({ type: 'error', message: '提现最低金额100' });
			// 	return false;
			// }
			if (this.form.radioType <= 0) {
				return this.$message({ type: 'error', message: '选择提现方式' })
			}
			console.log(typeof (parseInt(this.form.amount)));

			if (this.form.type == 1 && parseInt(this.form.amount) > this.user_info.money) {
				return this.$message({ type: 'error', message: '当前余额金额不足，请确认提现金额' });

			}
			if (this.form.type == 2 && parseInt(this.form.amount) > this.user_info.wallet) {
				return this.$message({ type: 'error', message: '当前收益金额不足，请确认提现金额' });

			}
			let data = {}
			data.amount = this.form.amount
			data.type = this.form.type
			this.curlPost("/api/users/tocash/save", data).then((res) => {
				if (res.data.code) {
					this.$message({
						type: 'success',
						message: '提现申请提交成功，请耐心等待!'
					})
					// 获取用户信息存入 local storage 更新用户钱包余额
					this.getUserInfo()
					this.form.amount = 0
					this.form.radioType = 0
					// setTimeout(() => {
					// 	this.$router.go(0)
					// }, 2000)
				} else {
					this.$message({
						type: 'error',
						message: res.data.msg
					});
				}
			})
		},
		// 获取用户信息
		getUserInfo() {
			this.curlGet('/api/user/info').then((res) => {
				if (res.data.code) {
					this.$nextTick(() => {
						this.user_info = res.data.data
						console.log(this.user_info);
					})

				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/Withdraw';
</style>